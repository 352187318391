import { ERROR_MESSAGE } from "../../constants";
import { ERROR_ICON } from "../../assets/images";
import "./ErrorMessage.scss";

export const ErrorMessage = () => {
  return (
    <div className="error-message">
      <img src={ERROR_ICON} className="error-logo" alt="logo" />
      <span className="error-text">{ERROR_MESSAGE}</span>
    </div>
  );
};
