import { EEventTypes, TSplitTimesLabels } from "../pages/RaceEvents/types";

export const LABELS_FOR_EVENT = {
    PAGAR: "Klart för start",
    TIME_TO_START: "Start om",
    TIME_TO_DEF: "Def om",
    RACE_STARTED: "Startat",
    RACE_FINISHED: "Mål",
    RACE_RESTARTED: "Omstart",
    SPLIT_TIMES: "Splittider"
};

export const TIMER_STOP = ["0:00", "00:00"];

export const NO_DATA_FOUND = "Nedräkning till defilering och start i svenska travlopp. Just nu finns det inga lopp att visa.";
export const ERROR_MESSAGE = "Något gick fel!";

export const FOOTER_TEXT = "Copyright/database right, Svensk Travsport. Hästsportinformationen som publicerats är upphovsrättsligt skyddat material. All form av kopiering, av hela eller delar av den publicerade hästsportinformationen, är inte tillåten.\nSvensk Travsport kan inte garantera att informationen som visas på denna sida är utan felaktigheter.";

export const APP_NAME = "Stallbacksklockan";

export const RACE = "Lopp";

export const MAX_ATTEMPT = 3;

export const SOCKET_EVENT = {
    OPEN: "open",
    CLOSE: "close",
    ERROR: "error",
    MESSAGE: "message"
}

export const PAGAR_DATA_TIMEOUT = 60000;

export const RECONNECT_TIMEOUT = 5000;

export const SPLIT_TIMES_LABELS: TSplitTimesLabels = {
    pace500: "Första 500",
    pace1000: "Första 1000",
    pace1500: "Första 1500",
    pace2000: "Första 2000",
    pace2500: "Första 2500",
    paceLast500: "paceLast500"
}

export const TIMEOUTS: Record<EEventTypes, number> = {
    [EEventTypes.RaceStarted]: 30000,
    [EEventTypes.RaceFinished]: 60000,
    [EEventTypes.SplitTimes]: 30000,
    [EEventTypes.RaceRestarted]: 30000,
    [EEventTypes.TimeToStart]: 10000,
    [EEventTypes.TimeToDef]: 10000,
  };

export const WAKE_LOCK_TIMEOUT = 20000;
