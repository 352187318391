export interface ITrack {
    name: string;
    code: string;
    sport: string;
    sportId: number;
    betId: number | null;
}

export const tracks: ITrack[] = [
    { name: 'Arvika', code: 'AR', sport: 'STC', sportId: 12, betId: 36 },
    { name: 'Axevalla', code: 'AX', sport: 'STC', sportId: 4, betId: 8 },
    { name: 'Bergsåker', code: 'B', sport: 'STC', sportId: 5, betId: 9 },
    { name: 'Blommeröd Galopp', code: 'BL', sport: 'SGC', sportId: 222, betId: 42 },
    { name: 'Boden', code: 'BO', sport: 'STC', sportId: 13, betId: 11 },
    { name: 'Bollnäs', code: 'BS', sport: 'STC', sportId: 14, betId: 12 },
    { name: 'Bro Park', code: 'BP', sport: 'SGC', sportId: 229, betId: 48 },
    { name: 'Bro Park Trav', code: 'BR', sport: 'STC', sportId: 4314, betId: 47 },
    { name: 'Dala Järna', code: 'DJ', sport: 'STC', sportId: 3040, betId: 47 },
    { name: 'Dannero', code: 'D', sport: 'STC', sportId: 15, betId: 13 },
    { name: 'Eskilstuna', code: 'E', sport: 'STC', sportId: 6, betId: 14 },
    { name: 'Färjestad', code: 'F', sport: 'STC', sportId: 7, betId: 15 },
    { name: 'Gärdet', code: 'GD', sport: 'SGC', sportId: 3560, betId: 47 },
    { name: 'Gävle', code: 'G', sport: 'STC', sportId: 8, betId: 16 },
    { name: 'Göteborg Trav', code: 'GT', sport: 'STC', sportId: 1520, betId: 47 },
    { name: 'Göteborg Galopp', code: 'GG', sport: 'SGC', sportId: 1400, betId: 45 },
    { name: 'Hagfors', code: 'HF', sport: 'STC', sportId: 1500, betId: 47 },
    { name: 'Hagmyren', code: 'H', sport: 'STC', sportId: 16, betId: 17 },
    { name: 'Halmstad', code: 'HD', sport: 'STC', sportId: 9, betId: 18 },
    { name: 'Hoting', code: 'HG', sport: 'STC', sportId: 17, betId: 37 },
    { name: 'Junsele', code: 'JU', sport: 'STC', sportId: 2920, betId: 47 },
    { name: 'Jägersro', code: 'J', sport: 'STC', sportId: 3, betId: 7 },
    { name: 'Jägersro Galopp', code: 'JÄ', sport: 'SGC', sportId: 221, betId: 34 },
    { name: 'Kalmar', code: 'KR', sport: 'STC', sportId: 18, betId: 19 },
    { name: 'Karlshamn', code: 'KH', sport: 'STC', sportId: 1240, betId: 44 },
    { name: 'Lindesberg', code: 'L', sport: 'STC', sportId: 19, betId: 21 },
    { name: 'Lycksele', code: 'LY', sport: 'STC', sportId: 20, betId: 38 },
    { name: 'Mantorp', code: 'MP', sport: 'STC', sportId: 21, betId: 22 },
    { name: 'Oviken', code: 'OV', sport: 'STC', sportId: 22, betId: 39 },
    { name: 'Romme', code: 'RO', sport: 'STC', sportId: 10, betId: 23 },
    { name: 'Rättvik', code: 'RÄ', sport: 'STC', sportId: 23, betId: 24 },
    { name: 'Siljan Istrav', code: 'SJ', sport: 'STC', sportId: 3680, betId: 47 },
    { name: 'Skellefteå', code: 'SK', sport: 'STC', sportId: 24, betId: 25 },
    { name: 'Solvalla', code: 'S', sport: 'STC', sportId: 1, betId: 5 },
    { name: 'Solänget', code: 'SÄ', sport: 'STC', sportId: 25, betId: 26 },
    { name: 'Strömsholm Galopp', code: 'ST', sport: 'SGC', sportId: 204, betId: 41 },
    { name: 'Tingsryd', code: 'TI', sport: 'STC', sportId: 1480, betId: 46 },
    { name: 'Toranda Trav', code: 'TN', sport: 'STC', sportId: 3909, betId: 47 },
    { name: 'Täby Galopp', code: 'TÄ', sport: 'SGC', sportId: 201, betId: 35 },
    { name: 'Täby Trav', code: 'TT', sport: 'STC', sportId: 1320, betId: 48 },
    { name: 'Umåker', code: 'U', sport: 'STC', sportId: 26, betId: 27 },
    { name: 'Vaggeryd', code: 'VG', sport: 'STC', sportId: 34, betId: 43 },
    { name: 'Vemdalen', code: 'VD', sport: 'STC', sportId: 2760, betId: 47 },
    { name: 'Visby', code: 'VI', sport: 'STC', sportId: 27, betId: 28 },
    { name: 'Vitsand', code: 'VS', sport: 'STC', sportId: 3520, betId: 11 },
    { name: 'Wången', code: 'WÅ', sport: 'STC', sportId: 2013, betId: 47 },
    { name: 'Xpress A', code: 'XA', sport: 'STC', sportId: 10000, betId: 40 },
    { name: 'Xpress B', code: 'XB', sport: 'STC', sportId: 10001, betId: 30 },
    { name: 'Åby', code: 'Å', sport: 'STC', sportId: 2, betId: 6 },
    { name: 'Åby Galopp', code: 'ÅB', sport: 'SGC', sportId: 202, betId: null },
    { name: 'Åmål', code: 'ÅM', sport: 'STC', sportId: 28, betId: 29 },
    { name: 'Åre Istav', code: 'ÅT', sport: 'STC', sportId: 1940, betId: 47 },
    { name: 'Årjäng', code: 'ÅR', sport: 'STC', sportId: 29, betId: 31 },
    { name: 'Örebro', code: 'Ö', sport: 'STC', sportId: 11, betId: 32 },
    { name: 'Östersund', code: 'ÖS', sport: 'STC', sportId: 30, betId: 33 }
];

export function betIdToSportId(betId: number): number | undefined {
    return tracks.find(track => track.betId === betId)?.sportId;
}