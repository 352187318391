let socket: WebSocket;
let url: string;

const getUrl = () => {
  if (window.location.hostname === "localhost") {
    url = "ws://localhost/now";
  } else {
    url = `wss://${window.location.hostname}`;
  }
};

export const getConnection = () => {
  getUrl();
  socket = new WebSocket(`${url}/live`);
  return socket;
};
