import React from 'react';
import { NO_DATA_FOUND } from '../../constants';
import "./NoDataFound.scss";

export const NoDataFound = () => {
  return (
    <div className='no-data-found'>
      <span className='no-data-text'> {NO_DATA_FOUND} </span>
    </div>
  )
}
