import { WAKE_LOCK_TIMEOUT } from "../constants";

declare global {
  interface Navigator {
    wakeLock?: {
      request(type: 'screen'): Promise<WakeLockSentinel>;
    };
  }

  interface WakeLockSentinel {
    release: () => Promise<void>;
  }
}

export const requestWakeLock = async (wakeLockRef: React.MutableRefObject<WakeLockSentinel | null>) => {
  if ('wakeLock' in navigator && navigator.wakeLock) {
    try {
      wakeLockRef.current = await navigator.wakeLock.request('screen');
      console.log('Wake Lock is active');
    } catch (err: unknown) {
      console.error(`Failed to acquire wake lock: ${err}`);
    }
  } else {
    console.warn('Wake Lock API not supported in this browser.');
  }
};

export const releaseWakeLock = (wakeLockRef: React.MutableRefObject<WakeLockSentinel | null>) => {
  if (wakeLockRef.current) {
    wakeLockRef.current.release().then(() => {
      wakeLockRef.current = null;
      console.log('Wake Lock released');
    }).catch(err => {
      console.error(`Failed to release wake lock: ${err}`);
    });
  }
};

export const handleVisibilityChange = (wakeLockRef: React.MutableRefObject<WakeLockSentinel | null>) => {
  if (document.visibilityState === 'visible') {
    requestWakeLock(wakeLockRef);
  } else {
    releaseWakeLock(wakeLockRef);
  }
};


export const setupWakeLockOrFallback = (wakeLockRef: React.MutableRefObject<WakeLockSentinel | null>) => {
  const visibilityChangeHandler = () => handleVisibilityChange(wakeLockRef);

  if ('wakeLock' in navigator) {
    // Request wake lock if API is available
    requestWakeLock(wakeLockRef);
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      releaseWakeLock(wakeLockRef);
    };
  } else {
    // If Wake Lock API is not available, keep the user interaction active
    const interval = setInterval(() => {
      document.body.style.visibility = 'hidden';
      document.body.style.visibility = 'visible';
    }, WAKE_LOCK_TIMEOUT);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
      clearInterval(interval);
    };
  }
};

