import { useCallback, useEffect, useRef, useState } from "react";
import { LOGO } from "../../assets/images";
import { getConnection } from "../../modules/websocket";
import {
  APP_NAME,
  RACE,
  SOCKET_EVENT,
  FOOTER_TEXT,
  TIMER_STOP,
  MAX_ATTEMPT,
  RECONNECT_TIMEOUT,
  TIMEOUTS
} from "../../constants";
import { EEventTypes, IEventResponseType } from "./types";
import { ErrorMessage } from "../ErrorMessage";
import { NoDataFound } from "../NoDataFound";
import "./RaceEvents.scss";
import packageInfo from "../../../package.json";
import { getEventLabelAndStyles, getImgSrc, getTrackName, replaceDotWithComma, setupWakeLockOrFallback } from "../../utils";

const RaceEvents = () => {
  const [trackInfo, setTrackInfo] = useState<{ data: IEventResponseType, timestamp: number }[]>([]);
  const [connection, setConnection] = useState<boolean>(false);
  const [connectionType, setConnectionType] = useState<string>("");
  const socket = useRef<WebSocket>();
  const attempt = useRef(1);
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  const socketConnection = useCallback(async () => {
    socket.current = await getConnection();
    if (!socket.current) {
      return;
    }
    setConnection(true);

    socket.current.onopen = (event) => {
      attempt.current = 1;
      setConnectionType(event.type);
    };

    socket.current.onclose = (event) => {
      setConnectionType(event.type);
      setConnection(false);
      if (attempt.current <= MAX_ATTEMPT) {
        setTimeout(() => {
          socketConnection();
          setConnection(true);
          attempt.current += 1;
        }, RECONNECT_TIMEOUT);
      } else {
        setConnectionType(SOCKET_EVENT.ERROR);
      }
    };

    socket.current.onerror = (event) => {
      setConnectionType(event.type);
    };
  }, []);

  useEffect(() => {
    socketConnection();
    return () => {
      socket.current?.close();
    };
  }, [socketConnection]);

  useEffect(() => {
    const currentSocket = socket.current;

    if (!currentSocket) {
      return;
    }

    currentSocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setConnectionType(event.type);

      if (message?.trackId) {
        const timestamp = Date.now();
        setTrackInfo((prevTrackInfo) => {
          const newTrackInfo = [...prevTrackInfo];
          const index = newTrackInfo.findIndex(item => item.data.trackId === message.trackId);

          if (index === -1) {
            newTrackInfo.push({ data: message, timestamp }); // Add new event
          } else {
            newTrackInfo[index] = { data: message, timestamp }; // Update existing event
          }

          return newTrackInfo;
        });
      }
    };

    return () => {
      currentSocket.onmessage = null;
    };
  }, [connection]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTrackInfo((prevTrackInfo) => {
        const now = Date.now();
        return prevTrackInfo.filter(({ data, timestamp }) => {
          const eventType = data.type as EEventTypes;
          return now - timestamp < TIMEOUTS[eventType];
        });
      });
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  // Wake Lock and Fallback Setup
  useEffect(() => {
    return setupWakeLockOrFallback(wakeLockRef);
  }, []);


  const eventTypeDetails = (data: IEventResponseType) => {
    const { type: eventType, time, raceNr, prelKmTime, paceName } = data;
    const timerStop = TIMER_STOP.indexOf(time);

    const {
      eventLabel = "",
      timeStyle = "",
      cardStyle = "inner-card",
      raceInfoTypeStyle = "race-info-type",
    } = getEventLabelAndStyles(eventType as EEventTypes, timerStop, paceName as unknown as string, prelKmTime);
    let displayTime;

    if (eventType === EEventTypes.SplitTimes || eventType === EEventTypes.RaceFinished) {
      displayTime = prelKmTime && prelKmTime.time ? replaceDotWithComma(prelKmTime.time) : '';
    }
    else {
      displayTime = time;
    }
    const imgSrc = getImgSrc(eventType as EEventTypes);

    return (
      <div className={cardStyle}>
        <div className="race-info">
          <span className={raceInfoTypeStyle}>{eventLabel}</span>
          <span className="race-info-num">{`${RACE} ${raceNr}`}</span>
        </div>

        {imgSrc && (!displayTime || displayTime === '') && (
          <span className={timeStyle}>
            <img src={imgSrc} className="logo-display" alt="logo" />
          </span>
        )}

        <div className="display-content-flex">
          <span className={timeStyle}>{displayTime}</span>
          {
            eventType === EEventTypes.RaceFinished ?
              displayTime || displayTime !== '' ?
                  <span className="race-kmtime-num">Prel KmTid</span>  : <span className={timeStyle}>{displayTime || '_ , _'}</span> :
              null
          }
        </div>
      </div>
    );
  };

  const displayCard = () => {
    return trackInfo?.length > 0 ? (
      trackInfo?.map(({ data }) => (
        <div key={data.trackId} className="track-info">
          <span className="track-name">
            {getTrackName(parseInt(data.trackId))}
          </span>
          {eventTypeDetails(data)}
        </div>
      ))
    ) : (
      <NoDataFound />
    );
  };

  const displayLayout = () => {
    if (connectionType === SOCKET_EVENT.ERROR) {
      return (
        <div className="track-container">
          <ErrorMessage />
        </div>
      );
    }
    return <div className="track-container">{displayCard()}</div>;
  };

  return (
    <div className="container">
      <div className="app-heading">
        <img src={LOGO} className="logo" alt="logo" />
        <header className="header-text">{APP_NAME}</header>
        <span style={{fontSize: "small",color: "red"}}>BETA</span>
      </div>
      <div className="inner-container">{displayLayout()}</div>
      <div className="footer">
        <span className="footer-text">{FOOTER_TEXT}</span>
        <span className="footer-text" style={{ marginTop: "5px" }}>
          Version: {packageInfo.version}
        </span>
      </div>
    </div>
  );
};

export default RaceEvents;
